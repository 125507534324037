var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Standard (single) select" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeStandard) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("In non ")]),
        _c("code", [_vm._v("multiple")]),
        _c("span", [_vm._v(" mode, ")]),
        _c("code", [_vm._v("<b-form-select>")]),
        _c("span", [_vm._v(" returns the a single ")]),
        _c("code", [_vm._v("value")]),
        _c("span", [_vm._v(" of the currently selected option. ")])
      ]),
      _c(
        "div",
        [
          _c("b-form-select", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
            _vm._v(" Selected: "),
            _c("strong", [_vm._v(_vm._s(_vm.selected))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }