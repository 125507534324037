var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Select sizing (displayed rows)" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeSize) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("You can use the ")]),
        _c("code", [_vm._v("select-size")]),
        _c("span", [
          _vm._v(
            " prop to switch the custom select into a select list-box, rather than a dropdown. Set the "
          )
        ]),
        _c("code", [_vm._v("select-size")]),
        _c("span", [
          _vm._v(
            " prop to a numerical value greater than 1 to control how many rows of options are visible."
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("b-form-select", {
            attrs: { options: _vm.options, "select-size": 4 },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
            _vm._v(" Selected: "),
            _c("strong", [_vm._v(_vm._s(_vm.selected))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }