var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Contextual states" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeStates) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Bootstrap includes validation styles for ")]),
        _c("code", [_vm._v("valid")]),
        _c("span", [_vm._v(" and ")]),
        _c("code", [_vm._v("invalid")]),
        _c("span", [_vm._v(" states on most form controls.")])
      ]),
      _c("b-card-text", [
        _c("span", [_vm._v("To apply one of the contextual state icons on ")]),
        _c("code", [_vm._v("<b-form-select>")]),
        _c("span", [_vm._v(", set the ")]),
        _c("code", [_vm._v("state")]),
        _c("span", [_vm._v(" prop to ")]),
        _c("code", [_vm._v("false")]),
        _c("span", [_vm._v(" (for invalid), ")]),
        _c("code", [_vm._v("true")]),
        _c("span", [_vm._v(" (for valid), or ")]),
        _c("code", [_vm._v("null")]),
        _c("span", [_vm._v(" (no validation state).")])
      ]),
      _c("b-form-select", {
        attrs: {
          options: _vm.options,
          state: _vm.selected === null ? false : true
        },
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _c("b-card-text", { staticClass: "mt-1 mb-0" }, [
        _vm._v(" Selected: "),
        _c("strong", [_vm._v(_vm._s(_vm.selected))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }