var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "BootstrapVue Select" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeBasic) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("span", [_vm._v("Bootstrap custom ")]),
        _c("code", [_vm._v("<select>")]),
        _c("span", [
          _vm._v(
            " using custom styles. Optionally specify options based on an array, array of objects, or an object."
          )
        ])
      ]),
      _c(
        "div",
        [
          _c("b-form-select", {
            attrs: { options: _vm.options },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c("b-form-select", {
            staticClass: "mt-1",
            attrs: { options: _vm.options, size: "sm" },
            model: {
              value: _vm.selected,
              callback: function($$v) {
                _vm.selected = $$v
              },
              expression: "selected"
            }
          }),
          _c("div", { staticClass: "mt-1" }, [
            _vm._v(" Selected: "),
            _c("strong", [_vm._v(_vm._s(_vm.selected))])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }